import {useCallback, useEffect, useState} from 'react';
import Aicp from 'core/Aicp';
import {CompanySourceStatus} from 'core/Aicp/types';
import {Notification} from 'core/Notification/index';
import AICP_COMPANY_SOURCE_STATUS_QUERY from 'graphql/pages/aicp/AicpCompanySourceStatus.graphql';
import {ResponseStatus, SuppressionListStatus} from 'src/types/graphql-types';
import {Logger} from 'utils/logger';
import {useLazyQuery} from '@apollo/client';

const logger = new Logger('useAicpCompanySourceStatusPollingQuery');

export const AICP_COMPANY_SOURCES_STATUS_POLL_INTERVAL = 30000;

interface CompanySourcesStatusVariables {
    idealCustomerProfileId: string;
}

interface CompanySourcesStatusPayload {
    status: ResponseStatus;
    idealCustomerProfile: Partial<Aicp>;
}
interface CompanySourcesStatusResponse {
    idealCustomerProfile: CompanySourcesStatusPayload;
}
interface UseAicpCompanySourceStatusPollingQueryProps {
    aicp: Aicp;
    refetchAicp: (aicpId: string) => void;
}

const hasProcessingSourceLists = ({
    idealCustomerProfile,
}: {
    idealCustomerProfile: Partial<Aicp>;
}): boolean => {
    const processingTargetSources =
        idealCustomerProfile?.targetSources?.filter(
            (targetSource) =>
                targetSource?.status === CompanySourceStatus.PROCESSING
        )?.length ?? 0;

    const processingSuppressionLists =
        idealCustomerProfile.accountSuppressionLists?.filter(
            (suppresionList) =>
                suppresionList?.status === SuppressionListStatus.Processing
        )?.length ?? 0;

    return processingTargetSources > 0 || processingSuppressionLists > 0;
};

export default function useAicpCompanySourceStatusPollingQuery({
    aicp,
    refetchAicp,
}: UseAicpCompanySourceStatusPollingQueryProps) {
    const shouldPoll = hasProcessingSourceLists({
        idealCustomerProfile: aicp,
    });

    const [isPolling, setIsPolling] = useState(false);

    const [executeQuery, {data, loading, error, stopPolling}] = useLazyQuery<
        CompanySourcesStatusResponse,
        CompanySourcesStatusVariables
    >(AICP_COMPANY_SOURCE_STATUS_QUERY, {
        pollInterval: AICP_COMPANY_SOURCES_STATUS_POLL_INTERVAL,
        variables: {idealCustomerProfileId: aicp.id},
        notifyOnNetworkStatusChange: true,
    });

    const processData = useCallback(() => {
        if (!isPolling) {
            executeQuery().catch((e) =>
                logger.error('Failed execute query', e)
            );
            setIsPolling(true);
        }

        if (data && loading === false && isPolling === true) {
            if (!hasProcessingSourceLists(data.idealCustomerProfile)) {
                stopPolling();
                setIsPolling(false);
                refetchAicp(aicp.id);
                Notification.success(
                    `Uploaded lists has been succesfully processed for aicp: "${aicp.name}"`,
                    {
                        removeAfter: 5,
                    }
                );
            }
        }
    }, [data, loading, stopPolling, refetchAicp, isPolling]);

    useEffect(() => {
        if (!shouldPoll) {
            stopPolling();
        } else if (error) {
            stopPolling();
            logger.error(
                `Error retrieving company source status  for  AICP with   ID ${aicp.id}`
            );
        } else {
            processData();
        }
    }, [data, stopPolling, shouldPoll]);

    return {data};
}
