import {Box} from 'components/mui';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import {
    PopperContentProps,
    PopperProvider,
    PopperProviderControlProps,
} from 'components/ui/poppers/PopperProvider';
import {UpdateAicpTagsMenu} from '../UpdateAicpTagsMenu';

interface EditTagsButtonProps {
    aicpId: string;
    aicpTags: string[];
    clientOrgTags: string[];
    refetchAicp: (aicpId: string) => void;
}

export default function EditTagsButton({
    aicpId,
    aicpTags,
    clientOrgTags,
    refetchAicp,
}: Readonly<EditTagsButtonProps>) {
    return (
        <PopperProvider
            flip
            preventOverflow
            placement="right"
            popperContent={({handleClose, setIsOpen}: PopperContentProps) => {
                return (
                    <UpdateAicpTagsMenu
                        aicpId={aicpId}
                        existingAicpTags={aicpTags}
                        clientOrgTags={clientOrgTags}
                        handleClose={handleClose}
                        setIsOpen={setIsOpen}
                        refetchAicp={refetchAicp}
                    />
                );
            }}
        >
            {({handleToggle, anchorRef}: PopperProviderControlProps) => {
                return (
                    <Box ref={anchorRef} aria-haspopup="true">
                        {aicpTags.length > 0 ? (
                            <EditIcon
                                data-testid="edit-tags-icon"
                                onClick={handleToggle}
                                cursor="pointer"
                                fontSize="small"
                                color="secondary"
                            />
                        ) : (
                            <AddIcon
                                data-testid="add-tags-icon"
                                onClick={handleToggle}
                                cursor="pointer"
                                fontSize="small"
                                color="secondary"
                            />
                        )}
                    </Box>
                );
            }}
        </PopperProvider>
    );
}
