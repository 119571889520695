import {MenuItem, Typography} from 'components/mui';
import {useMutation} from 'graphql/client';
import {Notification} from 'core/Notification/index';
import UPDATE_AICP from 'graphql/pages/aicps/createNewVersionAicp.graphql';
import {SUB_MENUS} from 'components/ui/pages/aicps/actions-menu/SubMenuController';
import {AICP_STATUS} from 'components/ui/pages/aicps/statuses.js';
import {Logger} from 'utils/logger';
import {logErrorMessages} from 'utils/graphqlResponse';
import ConfirmationDialog from 'components/ui/dialogs/ConfirmationDialog';
import {useState} from 'react';

const logger = new Logger('ActiveAicpMenuItems');

const generateConfirmationDialogTypography = (
    isFilteredByOutdatedIndustries
) => {
    if (isFilteredByOutdatedIndustries) {
        return (
            <>
                <Typography>
                    This aiCP is using the older Rev Industry classification.{' '}
                </Typography>
                <br />
                <Typography>
                    Updating this aiCP will refresh the aiCP data and
                    automatically map to the new industries.{' '}
                    <strong>
                        This may alter the results and will take some time to
                        complete.{' '}
                    </strong>
                    If you do not want new results, cancel this action and
                    create a new aiCP.
                </Typography>
            </>
        );
    }
    return (
        <Typography>
            This action will refresh the aiCP data, resulting in the rescoring
            of companies. Please note that{' '}
            <strong>these changes may take some time to complete</strong>.
        </Typography>
    );
};

type ActiveAicpMenuItemsProps = {
    isFilteredByOutdatedIndustries: boolean;
    currentStatus: string;
    setCurrentSubMenu: (subMenu: string) => void;
    isSuppressionDisabled: boolean;
    isAddComparisonDisabled: boolean;
    aicpId: string;
    hasOldIndustryStructure?: boolean;
    refetchAllAicps: () => void;
};

const ActiveAicpMenuItems = ({
    isFilteredByOutdatedIndustries,
    currentStatus,
    setCurrentSubMenu,
    isSuppressionDisabled,
    isAddComparisonDisabled,
    aicpId,
    refetchAllAicps,
}: ActiveAicpMenuItemsProps) => {
    const [updateAicp] = useMutation(UPDATE_AICP, {
        onError() {
            Notification.error('An error occurred while trying to update aiCP');
        },
    });

    const [isDialogOpen, setDialogOpen] = useState(false);

    const updateAicpOnClick = () => {
        updateAicp({variables: {aicpId: aicpId}})
            .then(() => {
                refetchAllAicps();
            })
            .catch((error) => {
                logErrorMessages(error, logger);
            });
        setCurrentSubMenu(null);
    };

    if (currentStatus === AICP_STATUS.active) {
        return (
            <div className="">
                <ConfirmationDialog
                    isOpen={isDialogOpen}
                    closeDialog={() => {
                        setDialogOpen(false);
                    }}
                    titleText="Refresh aiCP Data?"
                    onConfirm={updateAicpOnClick}
                    confirmButtonText="Continue"
                >
                    {generateConfirmationDialogTypography(
                        isFilteredByOutdatedIndustries
                    )}
                    <br />
                    <Typography> Would you like to proceed? </Typography>
                </ConfirmationDialog>

                <MenuItem
                    onClick={() => {
                        setDialogOpen(true);
                    }}
                >
                    <Typography component="span" className="menu-item">
                        Refresh aiCP Data
                    </Typography>
                </MenuItem>

                <MenuItem
                    data-testid="btn-add-suppression"
                    onClick={() => {
                        setCurrentSubMenu(SUB_MENUS.addSuppressionMenu);
                    }}
                    disabled={isSuppressionDisabled}
                >
                    <Typography
                        component="span"
                        className={
                            isSuppressionDisabled
                                ? 'menu-item-disabled'
                                : 'menu-item'
                        }
                    >
                        Add Suppression
                    </Typography>
                </MenuItem>

                <MenuItem
                    data-testid="btn-add-comparison-list"
                    onClick={() => {
                        setCurrentSubMenu(SUB_MENUS.addComparisonListMenu);
                    }}
                    disabled={isAddComparisonDisabled}
                >
                    <Typography
                        component="span"
                        className={
                            isAddComparisonDisabled
                                ? 'menu-item-disabled'
                                : 'menu-item'
                        }
                    >
                        Add Comparison List
                    </Typography>
                </MenuItem>
            </div>
        );
    }
    return null;
};

export default ActiveAicpMenuItems;
