import {useLazyQuery} from 'graphql/client';
import {AICP_STATUS} from 'components/ui/pages/aicps/statuses.js';
import AICP_STATUS_QUERY from 'graphql/pages/aicp/aicpStatus.graphql';
import {Logger} from 'utils/logger';
import {useCallback, useEffect, useState} from 'react';

const logger = new Logger('useAicpStatusPollingQuery');

export const AICP_STATUS_POLL_INTERVAL = 30000;

const isInPendingStates = (status) => {
    if (status !== AICP_STATUS.processing && status !== AICP_STATUS.updating) {
        return false;
    }
    return true;
};

export default function useAicpStatusPollingQuery({
    id,
    name,
    initialStatus,
    refetchAicp,
}: {
    id: string;
    name: string;
    initialStatus: string;
    refetchAicp: (aicpId: string) => void;
}) {
    const shouldPoll = isInPendingStates(initialStatus);
    const [isPolling, setIsPolling] = useState(false);

    const [executeQuery, {data, loading, error, stopPolling}] = useLazyQuery(
        AICP_STATUS_QUERY,
        {
            pollInterval: AICP_STATUS_POLL_INTERVAL,
            variables: {idealCustomerProfileId: id},
            notifyOnNetworkStatusChange: true,
        }
    );

    const finishPolling = (status) => {
        stopPolling();
        setIsPolling(false);

        if (status === AICP_STATUS.failed) {
            logger.error(`Failed to create ${name}.`);
        }

        refetchAicp(id);
    };

    const processData = useCallback(() => {
        if (!isPolling) {
            executeQuery().catch((e) =>
                logger.error('Failed execute query', e)
            );
            setIsPolling(true);
        }

        if (data && loading === false && isPolling === true) {
            const status =
                data.idealCustomerProfile.idealCustomerProfile.status;
            if (!isInPendingStates(status)) {
                finishPolling(status);
            }
        }
    }, [data, loading, stopPolling, finishPolling, isPolling]);

    useEffect(() => {
        if (!shouldPoll) {
            stopPolling();
        } else if (error) {
            stopPolling();
            logger.error(`Error retrieving aicp status for  AICP: ${name}`);
        } else {
            processData();
        }
    }, [data, shouldPoll, stopPolling]);
}
