import {MenuItem, Typography, ListSubheader} from 'components/mui';
import StyledLinearProgress from 'components/ui/graphics/StyledLinearProgress';
import ComparisonListContent from 'components/ui/pages/aicps/actions-menu/comparison-list-content/ComparisonListContent';
import SuppressionContent from 'components/ui/pages/aicps/actions-menu/suppression-content/SuppressionContent';
import {AICP_STATUS} from 'components/ui/pages/aicps/statuses.js';
import StyledActionsMenuList from 'components/ui/menu/StyledActionsMenuList';
import Link from 'components/ui/links/Link';
import useAicpCardActionsMenuCurrentMenuMutations from 'components/ui/pages/aicps/actions-menu/hooks/useAicpCardActionsMenuCurrentMenuMutations';
import SubMenuController, {
    SUB_MENUS,
} from 'components/ui/pages/aicps/actions-menu/SubMenuController';
import ActiveAicpMenuItems from 'components/ui/pages/aicps/actions-menu/ActiveAicpMenuItems';
import Aicp from 'core/Aicp';
import {Logger} from 'utils/logger';
import {logErrorMessages} from 'utils/graphqlResponse';
import {CompanySourceStatus, CsvCompanySource} from 'core/Aicp/types';
import UploadedFile from 'core/UploadedFile';
import {checkIfFilteredByOutdatedIndustries} from 'components/util/accountFilterUtils';
import {AccountSuppressionList} from 'src/types/graphql-types';

const logger = new Logger('AicpCardActionsMenuCurrentMenu');

const MAX_NUMBER_OF_COMPARISON_LISTS = 3;

type AicpCardActionsMenuCurrentMenuProps = {
    aicp: Aicp;
    currentStatus: string;
    accountSuppressionLists: AccountSuppressionList[];
    comparisonLists: CsvCompanySource[];
    isParentPopperOpen: boolean;
    popperHandleListKeyDown: () => void;
    currentSubMenu: string;
    setCurrentSubMenu: (subMenu: string) => void;
    refetchAicp: (aicpId: string) => void;
    refetchAllAicps: () => void;
};

const LoadingMenuItem = ({isLoading}: {isLoading: boolean}) => {
    return isLoading ? (
        <StyledLinearProgress width="100%" position="absolute" top={0} />
    ) : null;
};

const AicpCardActionsMenuCurrentMenu = ({
    aicp,
    currentStatus,
    accountSuppressionLists,
    comparisonLists,
    currentSubMenu,
    isParentPopperOpen,
    setCurrentSubMenu,
    refetchAicp,
    refetchAllAicps,
    popperHandleListKeyDown,
}: AicpCardActionsMenuCurrentMenuProps) => {
    const {
        loading,
        addSuppressionFile,
        removeAccountSuppressionListFromIcp,
        addComparisonList,
        removeComparisonListFromIcp,
    } = useAicpCardActionsMenuCurrentMenuMutations(aicp.id, setCurrentSubMenu);

    const isSuppressionDisabled = accountSuppressionLists.length > 0 || loading;
    const validComparisonLists = comparisonLists.filter(
        (comparisonList: CsvCompanySource) =>
            [
                CompanySourceStatus.PROCESSING,
                CompanySourceStatus.ACTIVE,
            ].includes(comparisonList.status)
    );

    const isAddComparisonDisabled =
        validComparisonLists.length >= MAX_NUMBER_OF_COMPARISON_LISTS ||
        loading;

    const isFilteredByOutdatedIndustries = checkIfFilteredByOutdatedIndustries(
        aicp.accountFilter?.industries
    );

    const addSuppressionListToAicp = (fileData: UploadedFile) => {
        addSuppressionFile({
            variables: {
                icpId: aicp.id,
                accountSuppressionFile: {
                    name: fileData.name,
                    temporaryUrl: fileData.url,
                },
            },
        })
            .then(() => {
                refetchAicp(aicp.id);
            })
            .catch((error) => {
                logErrorMessages(error, logger);
            });
    };

    const addComparisonListToAicp = (
        fileData: UploadedFile,
        fileName: string
    ) => {
        addComparisonList({
            variables: {
                icpId: aicp.id,
                fileName: fileName,
                comparisonFile: {
                    name: fileData.name,
                    temporaryUrl: fileData.url,
                },
            },
        })
            .then(() => {
                refetchAicp(aicp.id);
            })
            .catch((error) => {
                logErrorMessages(error, logger);
            });
    };

    if (currentSubMenu) {
        return (
            <SubMenuController
                aicp={aicp}
                currentSubMenu={currentSubMenu}
                setCurrentSubMenu={setCurrentSubMenu}
                isSuppressionDisabled={isSuppressionDisabled}
                isAddComparisonDisabled={isAddComparisonDisabled}
                addSuppressionListToAicp={addSuppressionListToAicp}
                addComparisonListToAicp={addComparisonListToAicp}
                refetchAicp={refetchAicp}
            />
        );
    }

    const handleRemoveSuppresionList = async (suppressionListId: string) => {
        await removeAccountSuppressionListFromIcp(suppressionListId);
        refetchAicp(aicp.id);
    };

    const handleRemoveComparisonList = async (comparisonListId: string) => {
        await removeComparisonListFromIcp(comparisonListId);
        refetchAicp(aicp.id);
    };

    return (
        <StyledActionsMenuList
            autoFocusItem={isParentPopperOpen}
            onKeyDown={popperHandleListKeyDown}
            data-testid="aicp-card-default-actions-menu"
        >
            <LoadingMenuItem isLoading={loading} />
            <ListSubheader component="div">
                <Typography component="span" className="menu-subheader">
                    Manage
                </Typography>
            </ListSubheader>
            <ActiveAicpMenuItems
                {...{
                    isFilteredByOutdatedIndustries,
                    currentStatus,
                    setCurrentSubMenu,
                    isSuppressionDisabled,
                    isAddComparisonDisabled,
                    isDynamic: aicp.isDynamic,
                    aicpId: aicp.id,
                    refetchAllAicps,
                }}
            />

            <MenuItem
                data-testid="btn-delete"
                onClick={() => {
                    setCurrentSubMenu(SUB_MENUS.deleteAicpMenu);
                }}
            >
                <Typography component="span" className="menu-item">
                    Delete
                </Typography>
            </MenuItem>

            <SuppressionContent
                accountSuppressionLists={accountSuppressionLists}
                removeAccountSuppressionListFromIcp={handleRemoveSuppresionList}
            />

            <ComparisonListContent
                comparisonLists={validComparisonLists}
                removeComparisonListFromIcp={handleRemoveComparisonList}
            />

            {currentStatus === AICP_STATUS.active && (
                // next line must not be a document fragment (mui MenuList)
                <div>
                    <hr />
                    <ListSubheader component="div">
                        <Typography component="span" className="menu-subheader">
                            Download
                        </Typography>
                    </ListSubheader>

                    <MenuItem data-testid="btn-download-export">
                        <Link
                            variant="body1"
                            href={`/aicp/${aicp.id}/companies/lookalike-companies`}
                        >
                            <Typography component="span" className="menu-item">
                                Download/Export Companies
                            </Typography>
                        </Link>
                    </MenuItem>
                </div>
            )}
        </StyledActionsMenuList>
    );
};

export default AicpCardActionsMenuCurrentMenu;
