import {Typography} from 'components/mui';
import AicpCardAccountFiltersPropTypes from 'components/ui/pages/aicps/aicp-card/prop-types/propTypes.js';
import {convertLargeIntegerToRoundedAbbreviation} from 'components/util/numberUtil';
import {DEFAULT_HEADCOUNT_BINS} from 'components/ui/filters/HeadcountFilter';

function headcountBinLabels(headcountBins) {
    const headcounts = headcountBins.map((headcountBin) => {
        if (headcountBin?.range?.max === null) {
            return `${headcountBin.range.min}+`;
        }
        return `${headcountBin.range.min} - ${headcountBin.range.max}`;
    });
    return headcounts.join(', ');
}

export default function AicpCardAccountFilters({headcountBins, revenueRange}) {
    const bins = headcountBins?.map((headcountBin) => headcountBin.bin) ?? [];
    let headcounts;

    const defaultBinsSelected =
        DEFAULT_HEADCOUNT_BINS.length === bins.length &&
        DEFAULT_HEADCOUNT_BINS.every((bin) => bins.includes(bin));

    if (defaultBinsSelected) {
        headcounts = '11+';
    } else if (
        headcountBins.length > 0 &&
        headcountBins.length <= DEFAULT_HEADCOUNT_BINS.length
    ) {
        headcounts = headcountBinLabels(headcountBins);
    } else {
        headcounts = '1+';
    }

    return (
        <>
            <Typography>
                <span className="AicpCard__filterName">Headcount:</span>
                {headcounts}
            </Typography>
            <Typography>
                <span className="AicpCard__filterName">Revenue:</span>
                {convertLargeIntegerToRoundedAbbreviation(revenueRange.min) ||
                    '0'}
                -
                {convertLargeIntegerToRoundedAbbreviation(revenueRange.max) ||
                    '5B+'}
            </Typography>
        </>
    );
}

AicpCardAccountFilters.propTypes = AicpCardAccountFiltersPropTypes;
