import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Stack,
    Typography,
} from 'components/mui';
import {Notification} from 'core/Notification/index';
import {useMutation} from 'graphql/client';
import REMOVE_ICP from 'graphql/pages/aicps/removeIcp.graphql';
import PROSPECT_FILTER_OPTIONS from 'graphql/pages/prospects/prospectFilterOptions.graphql';
import ASSIGNED_ACCOUNTS from 'graphql/user/queries/assignedAccounts.graphql';
import styled from 'styled-components';
import {logger} from 'utils/logger';

const DeleteMenu = styled.div`
    width: 480px;
`;

const DialogContent = styled(Box)`
    min-height: 175px;
`;

interface DeleteIcpMenuProps {
    icpName: string;
    setCurrentSubMenu: (subMenu: string) => void;
    icpId: string;
    refetchAicp: (aicpId: string) => void;
}

const DeleteIcpMenu = ({
    icpName,
    setCurrentSubMenu,
    icpId,
    refetchAicp,
}: DeleteIcpMenuProps) => {
    const [removeIcp, {loading}] = useMutation(REMOVE_ICP, {
        refetchQueries: [
            {query: ASSIGNED_ACCOUNTS},
            {query: PROSPECT_FILTER_OPTIONS},
        ],
        onCompleted(data: {removeIcp?: {status?: {success: boolean}}}) {
            const icpSuccessfullyRemoved = data?.removeIcp?.status?.success;

            if (icpSuccessfullyRemoved) {
                refetchAicp(icpId);
                Notification.success('Successfully deleted aiCP: ' + icpName);
            } else {
                Notification.error(
                    'An error occurred while trying to delete this aiCP'
                );
            }
        },
        onError() {
            Notification.error(
                'An error occurred while trying to delete this aiCP'
            );
        },
    });

    const cancelHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setCurrentSubMenu(null);
    };

    const handleOnDeleteAicp = () => {
        removeIcp({variables: {id: icpId}}).catch((e: Error) => {
            logger.error(`Failed to Delete the aiCP: ${e?.message}`);
            Notification.error('Failed to Delete the aiCP. Please try again.');
        });
    };
    return (
        <DeleteMenu data-testid="delete-aicp-menu-main">
            <Box p={4} pr={5} display="flex" alignItems="center">
                <Box display="flex" flexGrow={1}>
                    <Typography variant="h6" component="h6">
                        Delete aiCP: {icpName}?
                    </Typography>
                </Box>

                <IconButton
                    aria-label="close"
                    size="small"
                    color="secondary"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                    onClick={cancelHandler}
                >
                    <CloseIcon />
                </IconButton>
            </Box>

            <hr style={{margin: 0}} />
            <DialogContent
                p={4}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
            >
                <Typography
                    className="delete-warning-text"
                    gutterBottom
                    component="div"
                >
                    <Box fontWeight="fontWeightSemibold">
                        By removing this aiCP you will no longer see its results
                        in the My Prospects section.
                    </Box>
                </Typography>

                <Stack direction="row" spacing={1} justifyContent="flex-end">
                    <Button variant="outlined" onClick={cancelHandler}>
                        Cancel
                    </Button>

                    <Button onClick={handleOnDeleteAicp} disabled={!!loading}>
                        Delete{' '}
                        {loading && (
                            <Box display="inline-block" ml={2} mt={1}>
                                <CircularProgress color="inherit" size={25} />
                            </Box>
                        )}
                    </Button>
                </Stack>
            </DialogContent>
        </DeleteMenu>
    );
};

export {DeleteIcpMenu};
