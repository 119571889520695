import AicpCard from 'components/ui/pages/aicps/aicp-card/AicpCard';
import getClientOrgTags from 'components/util/getClientOrgTags';
import Aicp from 'core/Aicp';
import {AicpStatus} from 'core/Aicp/types';
import {useMemo} from 'react';

interface AicpCardsProps {
    aicps: Aicp[];
    refetchAicp: (aicpId: string) => void;
    refetchAllAicps: () => void;
}

const AicpCards = ({aicps, refetchAicp, refetchAllAicps}: AicpCardsProps) => {
    const aicpsToRender = useMemo(() => {
        return aicps
            .filter((aicp) =>
                [AicpStatus.ACTIVE, AicpStatus.PROCESSING].includes(aicp.status)
            )
            .map((item) => ({
                ...item,
                displayName: item.name.toUpperCase(),
            }));
    }, [aicps]);

    return (
        <>
            {aicpsToRender.map((aicp) => (
                <AicpCard
                    key={aicp.id}
                    aicp={aicp}
                    clientOrgTags={getClientOrgTags(aicps)}
                    refetchAicp={refetchAicp}
                    refetchAllAicps={refetchAllAicps}
                />
            ))}
        </>
    );
};

export default AicpCards;
