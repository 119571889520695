import AddSuppressionMenu from 'components/ui/pages/aicps/actions-menu/AddSuppressionMenu';
import AddComparisonListMenu from 'components/ui/pages/aicps/actions-menu/AddComparisonListMenu';
import {DeleteIcpMenu} from 'components/ui/pages/aicps/actions-menu/DeleteIcpMenu';
import UploadedFile from 'core/UploadedFile';
import Aicp from 'core/Aicp';

export const SUB_MENUS = {
    deleteAicpMenu: 'delete',
    addSuppressionMenu: 'add-suppression',
    addComparisonListMenu: 'add-comparison-list',
};

type SubMenuControllerProps = {
    aicp: Aicp;
    currentSubMenu: string;
    setCurrentSubMenu: (subMenu: string) => void;
    addSuppressionListToAicp: (fileData: UploadedFile) => void;
    addComparisonListToAicp: (fileData: UploadedFile, fileName: string) => void;
    isSuppressionDisabled: boolean;
    isAddComparisonDisabled: boolean;
    refetchAicp: (aicpId: string) => void;
};

const SubMenuController = ({
    aicp,
    currentSubMenu,
    setCurrentSubMenu,
    addSuppressionListToAicp,
    addComparisonListToAicp,
    isSuppressionDisabled,
    isAddComparisonDisabled,
    refetchAicp,
}: SubMenuControllerProps) => {
    switch (currentSubMenu) {
        case SUB_MENUS.deleteAicpMenu:
            return (
                <DeleteIcpMenu
                    icpName={aicp.name}
                    icpId={aicp.id}
                    setCurrentSubMenu={setCurrentSubMenu}
                    refetchAicp={refetchAicp}
                />
            );
        case SUB_MENUS.addSuppressionMenu:
            return (
                <AddSuppressionMenu
                    aicpName={aicp.name}
                    aicpId={aicp.id}
                    setCurrentSubMenu={setCurrentSubMenu}
                    addSuppressionListToAicp={addSuppressionListToAicp}
                    isSuppressionDisabled={isSuppressionDisabled}
                />
            );
        case SUB_MENUS.addComparisonListMenu:
            return (
                <AddComparisonListMenu
                    aicpName={aicp.name}
                    aicpId={aicp.id}
                    setCurrentSubMenu={setCurrentSubMenu}
                    addComparisonListToAicp={addComparisonListToAicp}
                    isAddComparisonDisabled={isAddComparisonDisabled}
                />
            );
        default:
            return null;
    }
};

export default SubMenuController;
