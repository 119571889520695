import {useEffect, useState} from 'react';
import useAicpsQuery from 'components/hooks/useAicpsQuery';
import useAicpTextFilter from 'components/ui/pages/aicps/useAicpsTextFilter';
import Aicp from 'core/Aicp';
import {useLazyQuery} from '@apollo/client';
import AICP from 'graphql/pages/aicp/aicp.graphql';
import useStateMachine from 'components/hooks/useStateMachine';
import {Logger} from 'utils/logger';
import {AICP_STATUS} from '../statuses';

const logger = new Logger('pages/aicps');

export const state = {
    LOADING_AICPS: 'loadingAicps',
    DISPLAYING_AICPS: 'displayingAicps',
    DISPLAYING_ERROR: 'error',
};

export const event = {
    AICPS_RECEIVED: 'aicpsReceived',
    ERROR: 'error',
    RELOAD: 'reload',
};

const aicpsStateMachineSpec = {
    initialState: state.LOADING_AICPS,
    states: {
        [state.LOADING_AICPS]: {
            [event.AICPS_RECEIVED]: {
                newState: state.DISPLAYING_AICPS,
            },
            [event.ERROR]: {
                newState: state.DISPLAYING_ERROR,
                action(error) {
                    logger.error('Error while loading aICPs', error);
                },
            },
        },
        [state.DISPLAYING_ERROR]: {
            [event.RELOAD]: {
                newState: state.LOADING_AICPS,
                action(refetchAicps: () => void) {
                    refetchAicps();
                },
            },
        },
    },
};

export const useAicps = (searchTerm: string) => {
    const [pageState, dispatchEvent] = useStateMachine(aicpsStateMachineSpec);
    const {filterAicps} = useAicpTextFilter(searchTerm);
    const [aicps, setAicps] = useState<Aicp[]>([]);

    const updateAicp = (updatedAicp: Aicp) => {
        const updatedAicps = aicps.map((aicp) => {
            if (aicp.id === updatedAicp.id) {
                return updatedAicp;
            }
            return aicp;
        });
        setAicps(updatedAicps);
    };

    const {aicps: availableAicps, refetchAicps} = useAicpsQuery({
        onCompleted() {
            dispatchEvent(event.AICPS_RECEIVED);
        },
        onError(error) {
            dispatchEvent(event.ERROR, error);
        },
        notifyOnNetworkStatusChange: true,
    });

    const [fetchAicp] = useLazyQuery(AICP, {fetchPolicy: 'network-only'});

    const refetchAicp = (aicpId: string) => {
        fetchAicp({
            variables: {idealCustomerProfileId: aicpId},
        })
            .then(({data: {idealCustomerProfile}}) => {
                const aicp = idealCustomerProfile?.idealCustomerProfile as Aicp;
                updateAicp(aicp);
            })
            .catch((error) => logger.error('Error retrieving AICP', error));
    };

    const refetchAllAicps = () => {
        refetchAicps()
            .then(({data}) => {
                if (data) {
                    const response = data?.idealCustomerProfiles;
                    if (
                        response?.status?.success &&
                        response?.idealCustomerProfiles
                    ) {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                        const aicps = response.idealCustomerProfiles.filter(
                            (aicp) =>
                                aicp.status !== AICP_STATUS.removed &&
                                aicp.status !== AICP_STATUS.archived
                        );
                        setAicps(aicps);
                    }
                }
            })
            .catch((error) => logger.error('Error retrieving AICPs', error));
    };

    useEffect(() => {
        if (availableAicps?.length > 0 && aicps.length === 0) {
            setAicps(availableAicps);
        }
    }, [availableAicps]);

    return {
        aicps: filterAicps(aicps),
        refetchAicps,
        refetchAllAicps,
        refetchAicp,
        pageState,
        dispatchEvent,
    };
};
