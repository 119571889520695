import {useMutation} from 'graphql/client';
import UPLOAD_SUPPRESSION from 'graphql/pages/aicp/uploadAccountSuppressionList.graphql';
import PROSPECT_FILTER_OPTIONS from 'graphql/pages/prospects/prospectFilterOptions.graphql';
import ASSIGNED_ACCOUNTS from 'graphql/user/queries/assignedAccounts.graphql';
import {Notification} from 'core/Notification/index';
import {GENERIC_UPLOAD_FILE_ERROR} from 'components/ui/pages/aicps/actions-menu/hooks/useAicpCardActionsMenuCurrentMenuMutations';
import Aicp from 'core/Aicp';
import {ResponseStatus} from 'core/GraphQL/types';
import UploadedFile from 'core/UploadedFile';
import {CsvCompanySource} from 'core/Aicp/types';

export type AddSuppressionFileMutationData = {
    uploadAccountSuppressionList: {
        idealCustomerProfile: Aicp;
        accountSuppressionList: CsvCompanySource;
        status: ResponseStatus;
    };
};

export type AddSuppressionFileMutationVariables = {
    icpId: string;
    accountSuppressionFile: UploadedFile;
};
export default function useAddSuppressionFile(
    setCurrentSubMenu: (subMenu: string | null) => void
) {
    const [addSuppressionFile, {loading: isSuppressionFileUploading}] =
        useMutation<
            AddSuppressionFileMutationData,
            AddSuppressionFileMutationVariables
        >(UPLOAD_SUPPRESSION, {
            refetchQueries: [
                {query: ASSIGNED_ACCOUNTS},
                {query: PROSPECT_FILTER_OPTIONS},
            ],
            onCompleted(data: AddSuppressionFileMutationData) {
                const suppressionListSuccessfullyAdded =
                    data?.uploadAccountSuppressionList?.status?.success;

                if (suppressionListSuccessfullyAdded) {
                    Notification.success(
                        'Upload succeeded. Some companies may not appear suppressed until a few minutes have passed.'
                    );
                } else {
                    Notification.error(GENERIC_UPLOAD_FILE_ERROR);
                }
                setCurrentSubMenu(null);
            },

            onError() {
                Notification.error(GENERIC_UPLOAD_FILE_ERROR);
            },
        });

    return {
        addSuppressionFile,
        loading: isSuppressionFileUploading,
    };
}
