import {isEmpty} from 'lodash';
import {MenuItem, Typography} from 'components/mui';
import DisplayFileItem from '../DisplayFileItem';
import {Logger} from 'utils/logger';
import {logErrorMessages} from 'utils/graphqlResponse';
import {
    AccountSuppressionList,
    SuppressionListStatus,
} from 'src/types/graphql-types';

const logger = new Logger('SuppressionContent');

interface SuppressionContentProps {
    accountSuppressionLists: AccountSuppressionList[];
    removeAccountSuppressionListFromIcp: (
        suppressionListId: string
    ) => Promise<void>;
}

const SuppressionContent = ({
    accountSuppressionLists,
    removeAccountSuppressionListFromIcp,
}: SuppressionContentProps) => {
    if (isEmpty(accountSuppressionLists)) {
        return <></>;
    }

    return (
        <div>
            <hr />
            <MenuItem disabled>
                <Typography
                    component="span"
                    className="menu-subheader"
                    data-testid="header-suppression"
                >
                    Suppression
                </Typography>
            </MenuItem>

            {accountSuppressionLists?.map((accountSuppressionList) => (
                <DisplayFileItem
                    key={accountSuppressionList.id}
                    fileName={accountSuppressionList.uploadedFile.name}
                    onDelete={() => {
                        removeAccountSuppressionListFromIcp(
                            accountSuppressionList.id
                        )?.catch((error) => logErrorMessages(error, logger));
                    }}
                    processing={
                        accountSuppressionList.status ===
                        SuppressionListStatus.Processing
                    }
                />
            ))}
        </div>
    );
};

export default SuppressionContent;
