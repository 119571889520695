import {useMutation} from 'graphql/client';
import ADD_COMPARISON_LIST from 'graphql/pages/aicp/addComparisonList.graphql';
import ASSIGNED_ACCOUNTS from 'graphql/user/queries/assignedAccounts.graphql';
import PROSPECT_FILTER_OPTIONS from 'graphql/pages/prospects/prospectFilterOptions.graphql';
import {Notification} from 'core/Notification/index';
import Aicp from 'core/Aicp';
import {ResponseStatus} from 'core/GraphQL/types';
import UploadedFile from 'core/UploadedFile';
import {GENERIC_UPLOAD_FILE_ERROR} from 'components/ui/pages/aicps/actions-menu/hooks/useAicpCardActionsMenuCurrentMenuMutations';
import AICP_COMPANY_SOURCE_STATUS_QUERY from 'graphql/pages/aicp/AicpCompanySourceStatus.graphql';

export type AddComparisonListMutationData = {
    addComparisonList: {
        idealCustomerProfile: Aicp;
        status: ResponseStatus;
    };
};

export type AddComparisonListMutationVariables = {
    icpId: string;
    fileName: string;
    comparisonFile: UploadedFile;
};

export default function useAddComparisonList(
    aicpId: string,
    setCurrentSubMenu: (subMenu: string | null) => void
) {
    const [addComparisonList, {loading: isComparisonListUploading}] =
        useMutation<
            AddComparisonListMutationData,
            AddComparisonListMutationVariables
        >(ADD_COMPARISON_LIST, {
            refetchQueries: [
                {query: ASSIGNED_ACCOUNTS},
                {query: PROSPECT_FILTER_OPTIONS},
                {
                    query: AICP_COMPANY_SOURCE_STATUS_QUERY,
                    variables: {idealCustomerProfileId: aicpId},
                },
            ],
            onCompleted(data: AddComparisonListMutationData) {
                const comparisonListSuccessfullyAdded =
                    data?.addComparisonList?.status?.success;
                const errorMessage =
                    data?.addComparisonList?.status?.error?.message || null;

                if (comparisonListSuccessfullyAdded) {
                    Notification.success(
                        'Comparison List successfully uploaded.'
                    );
                } else if (errorMessage) {
                    Notification.error(errorMessage);
                }
                setCurrentSubMenu(null);
            },
            onError() {
                Notification.error(GENERIC_UPLOAD_FILE_ERROR);
            },
        });

    return {
        addComparisonList,
        loading: isComparisonListUploading,
    };
}
