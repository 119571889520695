import WarningIcon from '@mui/icons-material/WarningAmberOutlined';
import {
    Box,
    CircularProgress,
    Grid,
    GridProps,
    Paper,
    Stack,
    Tooltip,
    Typography,
} from 'components/mui';
import AicpCardAccountFilters from 'components/ui/pages/aicps/aicp-card/AicpCardAccountFilters';
import AicpCardHeader from 'components/ui/pages/aicps/aicp-card/AicpCardHeader';
import AicpCardIndustries from 'components/ui/pages/aicps/aicp-card/AicpCardIndustries';
import AicpCardTags from 'components/ui/pages/aicps/aicp-card/AicpCardTags';
import EditTagsButton from 'components/ui/pages/aicps/aicp-card/EditTagsButton';
import {checkIfFilteredByOutdatedIndustries} from 'components/util/accountFilterUtils';
import styled from 'styled-components';
import Aicp from 'core/Aicp';
import useAicpCompanySourcesStatusPollingQuery from 'components/ui/pages/aicps/useAicpCompanySourcesStatusPollingQuery';
import {CompanySourceStatus, CsvCompanySource} from 'core/Aicp/types';

const StyledCard = styled(Paper)`
    padding: ${({theme}) => theme.spacing(3)};
    margin-bottom: ${({theme}) => theme.spacing(2)};

    && {
        border-radius: 0px;
    }
`;

const CardDetails = styled(Box)`
    .item-title {
        color: ${({theme}) => theme.palette.primary.main};
        font-size: 1.5rem;
        font-weight: ${({theme}) => theme.typography.fontWeightSemibold};
        line-height: 1.8rem;
        margin-bottom: 22px;
    }

    p {
        font-size: 1.4rem;
        line-height: 2.4rem;
    }

    .AicpCard__filterName {
        color: ${({theme}) => theme.palette.secondary.dark};
        font-weight: ${({theme}) => theme.typography.fontWeightSemibold};
        display: inline-block;
        margin-right: 12px;
    }

    .AicpCard__GridItem {
        background: #f9f9f9;
        padding: ${({theme}) => theme.spacing(2)};
        height: 100%;
        overflow-x: hidden;
    }
`;

const StyledWarning = styled(WarningIcon)`
    cursor: pointer;
`;

const aicpHasComparisonLists = (aicp) => {
    return aicp?.targetSources && aicp?.targetSources?.length > 0;
};

interface FilenameTextProps {
    filename: string;
    status?: string;
}

const FilenameText = ({filename, status = 'ACTIVE'}: FilenameTextProps) => {
    return (
        <Stack direction="row">
            <Typography
                style={{wordWrap: 'break-word'}}
                className="AicpCard__fileName"
            >
                {filename}
            </Typography>

            {status === CompanySourceStatus.PROCESSING && (
                <Box pl={1} alignContent={'center'} alignSelf={'center'}>
                    <CircularProgress size={12} />
                </Box>
            )}
        </Stack>
    );
};

interface AicpInfoItemProps extends GridProps {
    title: string;
    icon?: React.ReactNode;
    dataTestId?: string;
    children: React.ReactNode;
}

const AicpInfoItem = ({
    title,
    icon,
    dataTestId,
    children,
    ...rest
}: AicpInfoItemProps) => {
    return (
        <Grid item {...rest} data-testid={dataTestId}>
            <Box className="AicpCard__GridItem">
                <Box display="flex" alignItems="top">
                    <Typography className="item-title" variant="h3">
                        {title}
                    </Typography>
                    <Box ml={1}>{icon}</Box>
                </Box>

                {children}
            </Box>
        </Grid>
    );
};

interface AicpCardProps {
    aicp: Aicp;
    clientOrgTags: string[];
    refetchAicp: (aicpId: string) => void;
    refetchAllAicps: () => void;
}

// eslint-disable-next-line complexity
const AicpCard = ({
    aicp,
    clientOrgTags,
    refetchAicp,
    refetchAllAicps,
}: AicpCardProps) => {
    const {id, accountFilter, tags: aicpTags} = aicp;

    useAicpCompanySourcesStatusPollingQuery({
        aicp,
        refetchAicp,
    });

    const seedList = aicp?.latestSeedList || null;
    const seedListName =
        seedList?.name ||
        (seedList as CsvCompanySource)?.uploadedFile?.name ||
        null;
    const aicpCreationDate = aicp.originalCreationDate.toLocaleDateString(
        'en-US',
        {
            month: 'long',
            day: '2-digit',
            year: 'numeric',
        }
    );

    const isFilteredByOutdatedIndustries = checkIfFilteredByOutdatedIndustries(
        accountFilter.industries
    );

    const updatedDate = aicp?.updatedAt || null;
    const formattedUpdatedDate = updatedDate?.toLocaleDateString('en-US', {
        month: 'long',
        day: '2-digit',
        year: 'numeric',
    });

    return (
        <StyledCard key={id} elevation={0} data-testid={`AicpCard-${id}`}>
            <AicpCardHeader
                aicp={aicp}
                refetchAicp={refetchAicp}
                refetchAllAicps={refetchAllAicps}
            />

            <CardDetails>
                <Grid container spacing={1}>
                    <AicpInfoItem
                        title="Seed List"
                        dataTestId="best-customer-list"
                        xs={12}
                        md={6}
                        lg={4}
                    >
                        {seedList && (
                            <FilenameText
                                key={seedList.createdAt}
                                filename={seedListName}
                            />
                        )}
                    </AicpInfoItem>

                    <AicpInfoItem
                        title="Firmographic Filters"
                        dataTestId="account-filters"
                        xs={6}
                        md={3}
                        lg={4}
                    >
                        <AicpCardAccountFilters
                            headcountBins={accountFilter?.headcountBins}
                            revenueRange={accountFilter?.revenueRange}
                        />
                    </AicpInfoItem>

                    <AicpInfoItem
                        title="Industries"
                        icon={
                            isFilteredByOutdatedIndustries && (
                                <Tooltip
                                    title={
                                        <Box>
                                            <Typography variant="subtitle2">
                                                The industries used to filter
                                                this aiCP have changed. Updating
                                                this aiCP will apply the new SDP
                                                industries and your results may
                                                change. If you want to retain
                                                these results then you should
                                                create a new aiCP.
                                            </Typography>
                                        </Box>
                                    }
                                    placement="top"
                                    arrow
                                >
                                    <StyledWarning
                                        fontSize="small"
                                        data-testid="outdated-industries-warning-icon"
                                    />
                                </Tooltip>
                            )
                        }
                        dataTestId="industries"
                        xs={6}
                        md={3}
                        lg={4}
                    >
                        <AicpCardIndustries accountFilter={accountFilter} />
                    </AicpInfoItem>

                    <AicpInfoItem title="States" dataTestId="states" xs md>
                        <Typography>
                            {accountFilter.states.join(', ') || 'Any state'}
                        </Typography>
                    </AicpInfoItem>

                    <AicpInfoItem
                        title="Creation date"
                        dataTestId="aicp-created-at"
                        xs
                    >
                        <Typography>{aicpCreationDate}</Typography>
                    </AicpInfoItem>

                    {updatedDate && (
                        <AicpInfoItem
                            title="Last Updated"
                            dataTestId="aicp-updated-at"
                            xs
                        >
                            <Typography>{formattedUpdatedDate}</Typography>
                        </AicpInfoItem>
                    )}

                    <AicpInfoItem
                        title="Comparison Lists"
                        xs={12}
                        md={6}
                        lg={4}
                    >
                        {aicpHasComparisonLists(aicp) &&
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                            aicp.targetSources
                                .filter((targetSource) =>
                                    ['ACTIVE', 'PROCESSING'].includes(
                                        targetSource.status
                                    )
                                )
                                .map((targetSource) => (
                                    <FilenameText
                                        key={targetSource.id}
                                        filename={targetSource.name}
                                        status={targetSource.status}
                                    />
                                ))}
                    </AicpInfoItem>

                    <Grid item xs={12} data-testid="tags">
                        <Box className="AicpCard__GridItem">
                            <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                <Box mr={2}>
                                    <Typography
                                        className="item-title"
                                        variant="h3"
                                    >
                                        Tags
                                    </Typography>
                                </Box>
                                <Box>
                                    <EditTagsButton
                                        aicpId={aicp.id}
                                        aicpTags={aicpTags}
                                        clientOrgTags={clientOrgTags}
                                        refetchAicp={refetchAicp}
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <AicpCardTags tags={aicpTags} />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </CardDetails>
        </StyledCard>
    );
};

export default AicpCard;
